import React, { CSSProperties, Component, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactJson from 'react-json-view';
import XMLViewer from 'react-xml-viewer'
import './App.css';
import DragAndDrop from './DragAndDrop';
const  AplRenderer = require('apl-viewhost-web')

const aplEmptyGraphic = {
    "type": "AVG",
    "version": "1.2",
    "width": "100px",
    "height": "100px",
    "styles" : {},
    "items" : []
}

const aplTemplate = {
  "type": "APL",
  "version": "1.4",
  "settings": {},
  "theme": "dark",
  "import": [],
  "resources": [],
  "styles": {},
  "onMount": [],
  "graphics": {
      "shape01": {}
  },
  "commands": {},
  "layouts": {},
  "mainTemplate": {
      "parameters": [
          "payload"
      ],
      "items": [
        {
            "type": "Frame",
            "width": "100%",
            "height": "100%",
            "item": [
                {
                    "type": "Container",
                    "width": "100%",
                    "minWidth": "100",
                    "height": "100%",
                    "minHeight": "100",
                    "item": [
                        {
                            "type": "VectorGraphic",
                            "width": "100%",
                            "height": "100%",
                            "alignSelf": "center",
                            "align": "center",
                            "source": "shape01",
                            "scale": "best-fit"
                        }
                    ],
                    "justifyContent": "center",
                    "alignItems": "center"
                }
            ],
            "backgroundColor": "lightgray"
        }
    ]
  }
}

const styleBoxOuter : CSSProperties = {
  display : "flex",
  margin : "10px",
  maxHeight : "600px"
}

const styleBoxCell : CSSProperties = {
  flex : 1,
  textAlign:"left",
  maxHeight:"600px"
}

const styleFirstBox : CSSProperties = {
  ...styleBoxCell,
  marginRight : "20px"
}

const styleDropTarget : CSSProperties = {
  display: "flex",
  flex:"0 0 auto",
  textAlign:"center",
  alignItems: "center",
  justifyContent: "center",
  width:"100%",
  height: "100%",
}

async function convertAVG(svgData? : string) : Promise<any> {
  if(svgData) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"svgData":svgData});

    var requestOptions : RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    return fetch("https://b2e6c68y43.execute-api.us-west-1.amazonaws.com/live/convertsvg", requestOptions)
      .then(async response => { 
        if(response.ok) {
          let avgObject = await response.text();
          return(JSON.parse(avgObject));
        }})
      .catch(error => console.log('error', error));
  }
}


interface Props {
  template : any
  aplInitialized : boolean
}

interface State {
  avgGraphics : any,
  svgContent? : string
}

class AplPreview extends Component<Props,State> {
  
  viewerRef = React.createRef<HTMLDivElement>()

  state : Readonly<State> = {
    avgGraphics: aplEmptyGraphic,
  }

  async handleDrop(fs : FileList) : Promise<void> {
    let fileType = fs.item(0)?.type;
    let fileName = fs.item(0)?.name;
    // make sure the file type appears to be an SVG
    if(fileType === "image/svg+xml") {
      let svgText = await fs.item(0)?.text();
      //let svgHost = document.getElementById("svgHost") as HTMLElement;
      //if(svgHost != null) {
      //  svgHost.innerHTML = svgText;
        //let svgElement = svgHost.children[0];
        //svgElement.setAttribute("width", "100%");
        //svgElement.setAttribute("height", "100%");
        // retrieve and render the AVG content
        this.setState({ svgContent : svgText });
        let avgObject = await convertAVG(svgText); // async call to update AVG viewer
        this.setState({ avgGraphics : avgObject });
    } else {
      alert(`File ${fileName} does not appear to be a SVG file.`);
    }
  }

  private composeAPL() : any {
    let aplDocument = JSON.parse(JSON.stringify(this.props.template));
    // { ...this.props.template, graphics : { shape01: this.state.avgGraphics } }
    aplDocument.graphics.shape01 = this.state.avgGraphics;
    return aplDocument;
  }

  render() {
    return (
    <div className="App">
      <APL aplInitialized={this.props.aplInitialized}
           aplContent={this.composeAPL()}
           svgContent={this.state.svgContent}
           onDrop={async (fs)=>this.handleDrop(fs)} />
    </div>
    );
  }
}


const APL = (props: {aplInitialized:boolean, aplContent:any, svgContent?:string, onDrop:((fs:FileList)=>Promise<void>)}) => {
  
  let isInitialized = props.aplInitialized;
  let content = props.aplInitialized ? AplRenderer.Content.create(JSON.stringify(props.aplContent)): null;
  if (content) {
     content.addData('payload', JSON.stringify({}))
  }

  let defaultSVG = `<svg viewbox="0 0 100 100"><text y="50%"  fill="black" dominantBaseline="middle" textAnchor="middle">Drop SVG Here</text></svg>`;
  
  useEffect(() => {
    if (!props.aplInitialized) return;
    let aplViewerNode = document.getElementById('aplViewer');
    if(aplViewerNode) { aplViewerNode.innerHTML = ''; }
      const renderer = AplRenderer.default.create({
      content: content /* return value of the AplRenderer.Content.create call */,
      view: document.getElementById('aplViewer') /* element where the APL document should be rendered to */,
      environment: {
        agentName: "APL Sandbox",
        agentVersion: "1.0",
        allowOpenUrl: true,
        disallowVideo: false,
      },
      viewport: {
        width: 1024,
        height: 600,
        dpi: 96
      },
      theme: "dark",
      developerToolOptions: {
        mappingKey: "auth-id",
        writeKeys: ["auth-banana", "auth-id"],
      },
      utcTime: Date.now(),
      localTimeAdjustment: -new Date().getTimezoneOffset() * 60 * 1000,
    });
    renderer.init()
  //}, [svgContent])
  })
  if (!isInitialized) return null
  return (
      <div style={styleBoxOuter}>
          <div style={{...styleBoxCell,maxHeight:"600px",maxWidth:"600px"}}>
          <Tabs forceRenderTabPanel={true} style={{minHeight:"600px",minWidth:"400px",marginRight:"10px"}}>
            <TabList>
              <Tab>SVG Image</Tab>
              <Tab>SVG Source</Tab>
            </TabList>
            <TabPanel>
              <DragAndDrop onDrop={props.onDrop}>
              <div style={{maxHeight:"600px",minHeight:"600px",height: "100%", width: "100%",position:"relative"}}>
                <div id="svgHost" style={styleDropTarget} dangerouslySetInnerHTML={{__html:(props.svgContent ?? defaultSVG)}}>
                </div>
              </div>
            </DragAndDrop>
            </TabPanel>
            <TabPanel>
              <div style={{width:"none", textAlign:"left", marginLeft:"10px", maxHeight:"600px", maxWidth:"600px", overflow:"scroll", border:"1px dashed gray"}}>
                <XMLViewer xml={props.svgContent ?? defaultSVG} />
              </div>
            </TabPanel>
          </Tabs>
          </div>
          <div style={{...styleBoxCell,minWidth:"1024px"}}>
            <Tabs forceRenderTabPanel={true}>
              <TabList>
                <Tab>AVG Preview</Tab>
                <Tab>AVG Source</Tab>
              </TabList>
              <TabPanel>
                <div id="aplViewer"></div>
              </TabPanel>
              <TabPanel>
                <div style={{...styleBoxCell, textAlign:"left", marginLeft:"10px", maxHeight:"600px", overflowY:"scroll", border:"1px dashed gray"}}>
                  <ReactJson src={props.aplContent.graphics.shape01} />
                </div>
              </TabPanel>
            </Tabs>
          </div>
      </div>
  )
}


function App() {
  const [aplInitialized, isAPLInitialized] = useState(false)
  useEffect(() => {
    AplRenderer.initEngine().then(() => {
      isAPLInitialized(true)
    }).catch((e: Error) => {
      console.log(e)
    })
  }, [])
  return (
    <>
    <h1 style={{padding:"5px",textAlign:"center"}}>SVG Converter</h1>
    <AplPreview template={aplTemplate} aplInitialized={aplInitialized} />
    </>
  );
}


export default App;